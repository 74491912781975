import pic1 from "../assets/gallery/pic-1.jpeg";
import pic2 from "../assets/gallery/pic-2.jpeg";
import pic3 from "../assets/gallery/pic-3.jpeg";
import pic4 from "../assets/gallery/pic-4.jpeg";
import pic5 from "../assets/gallery/pic-5.jpeg";
import pic6 from "../assets/gallery/pic-6.jpeg";
import pic7 from "../assets/gallery/pic-7.jpeg";
import pic8 from "../assets/gallery/pic-8.jpeg";
import pic9 from "../assets/gallery/pic-9.jpeg";
import pic10 from "../assets/gallery/pic-10.jpeg";
import pic11 from "../assets/gallery/pic-11.jpeg";
import pic12 from "../assets/gallery/pic-12.jpeg";
import pic13 from "../assets/gallery/pic-13.jpeg";
import pic14 from "../assets/gallery/pic-14.jpeg";
import pic15 from "../assets/gallery/pic-15.jpeg";
import pic16 from "../assets/gallery/pic-16.jpeg";
import pic17 from "../assets/gallery/pic-17.jpeg";
import pic18 from "../assets/gallery/pic-18.jpeg";
import pic19 from "../assets/gallery/pic-19.jpeg";
import pic20 from "../assets/gallery/pic-20.jpeg";
import pic21 from "../assets/gallery/pic-21.jpeg";
import pic22 from "../assets/gallery/pic-22.jpeg";
import pic23 from "../assets/gallery/pic-23.jpeg";

export default {
  pic1,
  pic2,
  pic3,
  pic4,
  pic5,
  pic6,
  pic7,
  pic8,
  pic9,
  pic10,
  pic11,
  pic12,
  pic13,
  pic14,
  pic15,
  pic16,
  pic17,
  pic18,
  pic19,
  pic20,
  pic21,
  pic22,
  pic23,
};
