import React from "react";
import { FiWhatsApp, FiFacebook, FiInstagram } from "react-icons/fi";
import { FaWhatsapp } from "react-icons/fa";

// import { FooterOverlay, Newsletter } from "../../components";
import { images } from "../../constants";
import "./Footer.css";

const Footer = () => (
  <div className="app__footer section__padding" id="login">
    <div className="app__footer-links_logo">
      <img src={images.landmark} alt="footer_logo" />
      <p className="p__opensans">
        &quot;<i> TO LEAVE A MARK IN THE LAND OF DUBAI </i> &quot;
      </p>
      <img src={images.spoon} className="spoon__img" />
      <div className="app__footer-links_icons">
        <a
          href="https://www.instagram.com/landmarkrestaurant.ae/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiFacebook />
        </a>
        <a
          href={`https://wa.me/+9710507924175`}
          target="_blank"
          rel="noopener noreferrer"
        >
          <FaWhatsapp />
        </a>
        <a
          href="https://www.instagram.com/landmarkrestaurant.ae/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FiInstagram />
        </a>
      </div>
    </div>
    <div>
      <p className="p__opensans">Landmark Restaurant ® All Rights reserved.</p>
    </div>
  </div>
);

export default Footer;
