import bg from "../assets/bg.png";
import chef from "../assets/chef.png";
import G from "../assets/landmark-black.PNG";
import spoon from "../assets/spoon.svg";
import welcome from "../assets/welcome.png";
import findus from "../assets/findus.png";
import quote from "../assets/quote.png";
import landmark from "../assets/landmark.png";
import location from "../assets/location.png";
import Whatsapp from "../assets/whatsapp-logo.png";
import google from "../assets/Google.png.png";

export default {
  bg,
  chef,
  G,
  spoon,
  welcome,
  findus,
  quote,
  landmark,
  location,
  Whatsapp,
  google,
};
