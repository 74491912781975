import React, { useState } from "react";
import "./MenuItem.css";

const MenuItem = ({ images }) => {
  console.log("====================================");
  console.log(images);
  console.log("====================================");
  const [currentIndex, setCurrentIndex] = useState(0);

  const goToPrevSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === 0 ? images.length - 1 : prevIndex - 1
    );
  };

  const goToNextSlide = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex === images.length - 1 ? 0 : prevIndex + 1
    );
  };

  return (
    <div className="carousel-container">
      <button className="prev" onClick={goToPrevSlide}>
        &#10094;
      </button>
      <button className="next" onClick={goToNextSlide}>
        &#10095;
      </button>
      <div className="carousel-slide">
        <img
          src={images[currentIndex]}
          alt="carousel-item"
          className="carousel-image"
        />
      </div>
    </div>
  );
  // return (
  //   <div className="carousel-container">
  //     <button className="prev" onClick={goToPrevSlide}>
  //       &#10094;
  //     </button>
  //     <button className="next" onClick={goToNextSlide}>
  //       &#10095;
  //     </button>
  //     <div
  //       className="carousel-slide"
  //       // style={{ backgroundImage: `url(${images[currentIndex]})` }}
  //     >
  //       <img src={images[currentIndex]} className="carousel-image" />
  //     </div>
  //   </div>
  // );
};

export default MenuItem;
