// import React, { useEffect, useState } from "react";

import { MenuItem } from "../../components";
import menuImages from "../../constants/menuImages";
import "./SpecialMenu.css";

const SpecialMenu = () => {
  //   const [img, setImg] = useState([]);
  //   useEffect(() => {
  //     let images = getMenuImages();
  //     setImg(menuImages);
  //   }, []);

  return (
    <div className="app__specialMenu flex__center section__padding" id="menu">
      <div className="app__specialMenu-title">
        <h1 className="headtext__cormorant">Our Menu</h1>
      </div>
      <div>
        {/* <img className="app__teamenu_img" src={images.teaMenu}></img> */}
        <MenuItem images={menuImages} />
      </div>
    </div>
  );
};
export default SpecialMenu;

/* <div className="app__specialMenu-menu">
      <div className="app__specialMenu-menu_wine  flex__center">
        <p className="app__specialMenu-menu_heading">Wine & Beer</p>
        <div className="app__specialMenu_menu_items">
          {data.wines.map((wine, index) => (
            <MenuItem
              key={wine.title + index}
              title={wine.title}
              price={wine.price}
              tags={wine.tags}
            />
          ))}
        </div>
      </div>

      <div className="app__specialMenu-menu_img">
        <img src={images.menu} alt="menu__img" />
      </div>

      <div className="app__specialMenu-menu_cocktails  flex__center">
        <p className="app__specialMenu-menu_heading">Cocktails</p>
        <div className="app__specialMenu_menu_items">
          {data.cocktails.map((cocktail, index) => (
            <MenuItem
              key={cocktail.title + index}
              title={cocktail.title}
              price={cocktail.price}
              tags={cocktail.tags}
            />
          ))}
        </div>
      </div>
    </div>

    <div style={{ marginTop: 15 }}>
      <button type="button" className="custom__button">
        View More
      </button>
    </div> */
