// export const getMenuImages = () => {
//   const images = [];
//   for (let i = 1; i <= 35; i++) {
//     images.push(`../assets/MenuImages/image-${i}.jpeg`);
//   }
//   //   console.log(images);
//   return images;
// };

// import { readdirSync } from "fs";
// import { join } from "path";

// const imagesFolder = "./src/assets/MenuImages"; // Adjust the path to your images folder

// const images = readdirSync(imagesFolder).map((file) => {
//   return join(imagesFolder, file);
// });

// export default images;

import image1 from "../assets/MenuImages/image-1.jpeg";
import image2 from "../assets/MenuImages/image-2.jpeg";
import image3 from "../assets/MenuImages/image-3.jpeg";
import image4 from "../assets/MenuImages/image-4.jpeg";
import image5 from "../assets/MenuImages/image-5.jpeg";
import image6 from "../assets/MenuImages/image-6.jpeg";
import image7 from "../assets/MenuImages/image-7.jpeg";
import image8 from "../assets/MenuImages/image-8.jpeg";
import image9 from "../assets/MenuImages/image-9.jpeg";
import image10 from "../assets/MenuImages/image-10.jpeg";
import image11 from "../assets/MenuImages/image-11.jpeg";
import image12 from "../assets/MenuImages/image-12.jpeg";
import image13 from "../assets/MenuImages/image-13.jpeg";
import image14 from "../assets/MenuImages/image-14.jpeg";
import image15 from "../assets/MenuImages/image-15.jpeg";
import image16 from "../assets/MenuImages/image-16.jpeg";
import image17 from "../assets/MenuImages/image-17.jpeg";
import image18 from "../assets/MenuImages/image-18.jpeg";
import image19 from "../assets/MenuImages/image-19.jpeg";
import image20 from "../assets/MenuImages/image-20.jpeg";
import image21 from "../assets/MenuImages/image-21.jpeg";
import image22 from "../assets/MenuImages/image-22.jpeg";
import image23 from "../assets/MenuImages/image-23.jpeg";
import image24 from "../assets/MenuImages/image-24.jpeg";
import image25 from "../assets/MenuImages/image-25.jpeg";
import image26 from "../assets/MenuImages/image-26.jpeg";
import image27 from "../assets/MenuImages/image-27.jpeg";
import image28 from "../assets/MenuImages/image-28.jpeg";
import image29 from "../assets/MenuImages/image-29.jpeg";
import image30 from "../assets/MenuImages/image-30.jpeg";
import image31 from "../assets/MenuImages/image-31.jpeg";
import image32 from "../assets/MenuImages/image-32.jpeg";
import image33 from "../assets/MenuImages/image-33.jpeg";
import image34 from "../assets/MenuImages/image-34.jpeg";
import image35 from "../assets/MenuImages/image-35.jpeg";

const menuImages = [
  image1,
  image2,
  image3,
  image4,
  image5,
  image6,
  image7,
  image8,
  image9,
  image10,
  image11,
  image12,
  image13,
  image14,
  image15,
  image16,
  image17,
  image18,
  image19,
  image20,
  image21,
  image22,
  image23,
  image24,
  image25,
  image26,
  image27,
  image28,
  image29,
  image30,
  image31,
  image32,
  image33,
  image34,
  image35,
];

export default menuImages;
