import React from "react";

import { SubHeading } from "../../components";
import { images } from "../../constants";

const FindUs = () => (
  <div className="app__bg app__wrapper section__padding" id="contact">
    <div className="app__wrapper_info">
      <h1 className="headtext__cormorant" style={{ marginBottom: "3rem" }}>
        Find Us
      </h1>
      <div className="app__wrapper-content">
        <p className="p__cormorant" style={{ color: "#DCCA87", marginTop: 4 }}>
          Landmark Restaurant ( New Branch | Al Rigga )
        </p>
        <p className="p__opensans_black">
          Al Salmiya Building - Al Rigga Rd - beside Delta Center - Deira -
          Dubai
        </p>
        <a href="tel:+971043375766">
          <p className="p__opensans_black"> +971 04 337 5766</p>
        </a>
        <div className="google_tabs">
          <a
            href="https://www.google.com/maps/place/Landmark+Restaurant+(+New+Branch+%7C+Al+Rigga+)/@25.2621382,55.3242932,16z/data=!4m6!3m5!1s0x3e5f5d4c986898db:0x3671d0fca5e4db4e!8m2!3d25.2636142!4d55.3241363!16s%2Fg%2F11txjjxkkd?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.location}
              style={{ width: 18, height: 26, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href={`https://wa.me/+9710542529691`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.Whatsapp}
              style={{ width: 30, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href="https://g.page/r/CU7b5KX80HE2EBE/review"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={images.google} style={{ width: 26 }}></img>
          </a>
        </div>
      </div>
      <div className="app__wrapper-content">
        <p className="p__cormorant" style={{ color: "#DCCA87", marginTop: 4 }}>
          Landmark Restaurant (DIP Br)
        </p>
        <p className="p__opensans_black">Dubai Investment Park - 2 - Dubai</p>
        <a href="tel:+971048830900">
          <p className="p__opensans_black"> +971 04 883 0900</p>
        </a>
        <div className="google_tabs">
          <a
            href="https://www.google.com/maps/place/Landmark+Restaurant+(DIP+Br)/@24.9750647,55.2050976,17z/data=!4m6!3m5!1s0x3e5f7235eb460e53:0x26a54aabbf9fe954!8m2!3d24.9750647!4d55.2050976!16s%2Fg%2F11gbnmf8ts?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.location}
              style={{ width: 18, height: 26, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href={`https://wa.me/+9710552323100`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.Whatsapp}
              style={{ width: 30, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href="https://g.page/r/CVTpn7-rSqUmEBE/review"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={images.google} style={{ width: 26 }}></img>
          </a>
        </div>
      </div>
      <div className="app__wrapper-content">
        <p className="p__cormorant" style={{ color: "#DCCA87", marginTop: 4 }}>
          Landmark Restaurant (Crown Mall Br)
        </p>
        <p className="p__opensans_black">
          Crown Mall - 2 83rd Street - Jebel Ali Industrial Area - Jabal Ali
          Industrial First - Dubai
        </p>
        <a href="tel:+971043308464">
          <p className="p__opensans_black">+ 971 04 330 8464</p>
        </a>
        <div className="google_tabs">
          <a
            href="https://www.google.com/maps/place/Landmark+Restaurant+(Crown+Mall+Br)/@24.9966587,55.1408559,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f0d66d7930063:0xfcd4494e97010bd2!8m2!3d24.9966587!4d55.1408559!16s%2Fg%2F11pyp6gt6j?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.location}
              style={{ width: 18, height: 26, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href={`https://wa.me/+9710507287911`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.Whatsapp}
              style={{ width: 30, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href="https://g.page/r/CdILAZdOSdT8EBE/review"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={images.google} style={{ width: 26 }}></img>
          </a>
        </div>
      </div>
      {/* <div className="app__wrapper-content">
        <p className="p__cormorant" style={{ color: "#DCCA87", marginTop: 4 }}>
          Landmark Restaurant (Al Quoz Br)
        </p>
        <p className="p__opensans_black">
          6 27 Street - Al Quoz - Al Quoz Industrial Area 2 - Dubai
        </p>
        <a href="tel:+971043381326">
          <p className="p__opensans_black">+971 04 338 1326</p>
        </a>
        <div className="google_tabs">
          <a
            href="https://www.google.com/maps/place/Landmark+Restaurant+(Al+Quoz+Br)/@25.1326405,55.2418545,17z/data=!3m1!4b1!4m6!3m5!1s0x3e5f696e975cc5f7:0x2666b7ceaddfbbea!8m2!3d25.1326405!4d55.2418545!16s%2Fg%2F11cm0p5j66?entry=ttu"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.location}
              style={{ width: 18, height: 26, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href={`https://wa.me/+9710507924175`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={images.Whatsapp}
              style={{ width: 30, marginRight: "1rem" }}
            ></img>
          </a>
          <a
            href="https://g.page/r/Ceq7363Ot2YmEBE/review"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src={images.google} style={{ width: 26 }}></img>
          </a>
        </div>
      </div> */}
    </div>

    <div className="app__wrapper_img">
      <img src={images.findus} alt="finus_img" />
    </div>
  </div>
);

export default FindUs;
