import React from "react";
import {
  BsInstagram,
  BsArrowLeftShort,
  BsArrowRightShort,
} from "react-icons/bs";

import images from "../../constants/gallery";
import "./Gallery.css";

const Gallery = () => {
  const scrollRef = React.useRef(null);

  const scroll = (direction) => {
    const { current } = scrollRef;

    if (direction === "left") {
      current.scrollLeft -= 300;
    } else {
      current.scrollLeft += 300;
    }
  };

  return (
    <div className="app__gallery flex__center">
      <div className="app__gallery-content">
        <h1 className="headtext__cormorant">Photo Gallery</h1>
        <p
          className="p__opensans"
          style={{ color: "#AAAAAA", marginTop: "2rem" }}
        >
          Embark on a visual feast through our Instagram gallery, showcasing the
          vibrant flavors and artistry that define our restaurant. Explore a
          tapestry of culinary delights that will leave your taste buds yearning
          for more.
        </p>
        <button type="button" className="custom__button">
          <a
            href="https://www.instagram.com/landmarkrestaurant.ae/"
            target="_blank"
            rel="noopener noreferrer"
          >
            View More
          </a>
        </button>
      </div>
      <div className="app__gallery-images">
        <div className="app__gallery-images_container" ref={scrollRef}>
          {[
            images.pic1,
            images.pic2,
            images.pic3,
            images.pic4,
            images.pic5,
            images.pic6,
            images.pic7,
            images.pic8,
            images.pic9,
            images.pic10,
            images.pic11,
            images.pic12,
            images.pic13,
            images.pic14,
            images.pic15,
            images.pic16,
            images.pic17,
            images.pic18,
            images.pic19,
            images.pic20,
            images.pic21,
            images.pic22,
            images.pic23,
          ].map((image, index) => (
            <div
              className="app__gallery-images_card flex__center"
              key={`gallery_image-${index + 1}`}
            >
              <img src={image} alt="gallery_image" />

              <BsInstagram
                className="gallery__image-icon"
                onClick={() => {
                  window.open(
                    "https://www.instagram.com/landmarkrestaurant.ae/",
                    "_blank"
                  );
                }}
              />
            </div>
          ))}
        </div>
        <div className="app__gallery-images_arrows">
          <BsArrowLeftShort
            className="gallery__arrow-icon bg-white"
            onClick={() => scroll("left")}
          />
          <BsArrowRightShort
            className="gallery__arrow-icon bg-white"
            onClick={() => scroll("right")}
          />
        </div>
      </div>
    </div>
  );
};

export default Gallery;
