import React from "react";
import { GiHamburgerMenu } from "react-icons/gi";
import { MdOutlineRestaurantMenu } from "react-icons/md";
import images from "../../constants/images";
import "./Navbar.css";

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = React.useState(false);
  const [toggleWhatsappMenu, setToggleWhatsappMenu] = React.useState(false);

  const WhatsappMenu = (e) => {
    e.preventDefault();
    setToggleWhatsappMenu(true);
  };
  return (
    <nav className="app__navbar">
      <div className="app__navbar-logo">
        <img src={images.landmark} alt="app__logo" />
      </div>
      <ul className="app__navbar-links">
        <li className="p__opensans">
          <a href="#home">Home</a>
        </li>
        <li className="p__opensans">
          <a href="#about">About</a>
        </li>
        <li className="p__opensans">
          <a href="#menu">Menu</a>
        </li>
        <li className="p__opensans">
          <a href="#contact">Contact</a>
        </li>
      </ul>
      {/* <div className="app__navbar-login">
        <a href="#login" className="p__opensans">
          Log In / Registration
        </a>
        <div />
        <a href="/" className="p__opensans">
          Book Table
        </a>
      </div> */}
      {/* <div>
        <ul>
          <li className="p__opensans">
            <a
              href={`https://wa.me/+9710507924175`}
              target="_blank"
              rel="noopener noreferrer"
            >
              Start WhatsApp Chat
            </a>
          </li>
        </ul>
      </div> */}
      {/* <div className="app__navbar-smallscreen">
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        /> */}
      {toggleWhatsappMenu ? (
        <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
          <MdOutlineRestaurantMenu
            fontSize={27}
            className="overlay__close"
            onClick={() => setToggleWhatsappMenu(false)}
          />
          {/* <ul className="app__chef-sign">
            <li>SELECT THE BRACHES ARE YOU NEAR BY</li>
          </ul> */}
          <ul className="app__navbar-smallscreen_links">
            <li>
              <a
                href={`https://wa.me/+9710542529691`}
                onClick={() => setToggleWhatsappMenu(false)}
              >
                Landmark Restaurant ( New Branch | Al Rigga )
              </a>
            </li>
            <li>
              <a
                href={`https://wa.me/+9710552323100`}
                onClick={() => setToggleWhatsappMenu(false)}
              >
                Landmark Restaurant (DIP Br)
              </a>
            </li>
            <li>
              <a
                href={`https://wa.me/+9710507287911`}
                onClick={() => setToggleWhatsappMenu(false)}
              >
                Landmark Restaurant (Crown Mall Br)
              </a>
            </li>
            {/* <li>
              <a
                href={`https://wa.me/+9710507924175`}
                onClick={() => setToggleWhatsappMenu(false)}
              >
                Landmark Restaurant (Al Quoz Br)
              </a>
            </li> */}
          </ul>
        </div>
      ) : (
        <div>
          <ul className="app__navbar-links">
            <img
              src={images.Whatsapp}
              onClick={(e) => {
                WhatsappMenu(e);
              }}
              style={{ width: 30, cursor: "pointer" }}
            ></img>
            <li className="p__opensans">
              <a
                href={``}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  WhatsappMenu(e);
                }}
              >
                WhatsApp
              </a>
            </li>
          </ul>
        </div>
      )}
      <div className="app__navbar-smallscreen">
        <div>
          <ul className="app__navbar-links">
            <li className="p__opensans">
              <a
                href={``}
                target="_blank"
                rel="noopener noreferrer"
                onClick={(e) => {
                  WhatsappMenu(e);
                }}
              >
                <img
                  src={images.Whatsapp}
                  // onClick={(e) => {
                  //   WhatsappMenu(e);
                  // }}
                  style={{ width: 30, cursor: "pointer" }}
                ></img>
              </a>
            </li>
          </ul>
        </div>
        <GiHamburgerMenu
          color="#fff"
          fontSize={27}
          onClick={() => setToggleMenu(true)}
        />
        {toggleMenu && (
          <div className="app__navbar-smallscreen_overlay flex__center slide-bottom">
            <MdOutlineRestaurantMenu
              fontSize={27}
              className="overlay__close"
              onClick={() => setToggleMenu(false)}
            />
            <ul className="app__navbar-smallscreen_links">
              <li>
                <a href="#home" onClick={() => setToggleMenu(false)}>
                  Home
                </a>
              </li>
              <li>
                <a href="#about" onClick={() => setToggleMenu(false)}>
                  About
                </a>
              </li>
              <li>
                <a href="#menu" onClick={() => setToggleMenu(false)}>
                  Menu
                </a>
              </li>
              <li>
                <a href="#contact" onClick={() => setToggleMenu(false)}>
                  Contact
                </a>
              </li>
            </ul>
          </div>
        )}
      </div>
    </nav>
  );
};

export default Navbar;
