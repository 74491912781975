import React from "react";

import { images } from "../../constants";
import "./Header.css";

const Header = () => (
  <div className="app__header app__wrapper section__padding" id="home">
    <div className="app__wrapper_info">
      <h1 className="app__header-h1">
        Discover the essence of culinary delight.
      </h1>
      <p className="p__opensans" style={{ margin: "2rem 0" }}>
        Experience the epitome of culinary excellence at our restaurant, where
        each dish is a work of art. From innovative flavors to timeless
        classics, immerse yourself in a gastronomic journey that will delight
        your senses and leave you craving for more.{" "}
      </p>
      <button type="button" className="custom__button">
        <a href="#menu">Explore Menu</a>
      </button>
    </div>

    <div className="app__wrapper_img">
      <img src={images.welcome} alt="header_img" />
    </div>
  </div>
);

export default Header;
