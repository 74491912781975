import React from "react";

import { images } from "../../constants";
import "./AboutUs.css";

const AboutUs = () => (
  <div
    className="app__aboutus app__bg flex__center section__padding"
    id="about"
  >
    {/* <div className="app__aboutus-overlay flex__center">
      <img src={images.G} alt="G_overlay" />
    </div> */}

    <div className="app__aboutus-content flex__center">
      <div className="app__aboutus-content_about">
        <h1 className="headtext__cormorant">About Us</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Welcome to Landmark Restaurant, where we offer an exquisite dining
          experience that celebrates the rich flavors of our region while adding
          a touch of international flair. Situated in the hearts of United Arab
          Emirates, Landmark has been a beloved culinary destination since 2010.
          At Landmark, we are passionate about creating memorable moments for
          our guests through exceptional food, warm hospitality, and a charming
          atmosphere.
        </p>
      </div>

      <div className="app__aboutus-content_knife flex__center">
        <img src={images.G} alt="about_knife" />
      </div>

      <div className="app__aboutus-content_history">
        <h1 className="headtext__cormorant">Our History</h1>
        <img src={images.spoon} alt="about_spoon" className="spoon__img" />
        <p className="p__opensans">
          Landmark Restaurant has been a cornerstone of culinary excellence
          since its establishment in 2010. Founded with a vision to provide
          exceptional dining experiences, Landmark quickly became a beloved
          fixture in Dubai's food scene. In its early years, Landmark focused on
          offering classic dishes with a modern twist, showcasing the talents of
          its innovative chefs and dedication to using the freshest ingredients
          available.
        </p>
      </div>
    </div>
  </div>
);

export default AboutUs;
